export class AdyenSoftPosInfo {
  public boarded: boolean;
  public installationId: string;
  public boardingRequestToken?: string;
  public boardingToken?: string;


  constructor(boarded: string, installationId: string, boardingRequestToken?: string, boardingToken?: string) {
    this.boarded = boarded === 'true';
    this.installationId = installationId;
    this.boardingRequestToken = boardingRequestToken;
    this.boardingToken = boardingToken;
  }
}
