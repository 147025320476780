import { IPaymentResult } from '../interfaces';
import { ICcvPaymentResult } from '@pos-common/interfaces';
import { PAYMENT_PROVIDERS } from '../constants/payment-providers.const';
import moment from 'moment';
import { TransactionResult } from '@paymash/capacitor-mypos-mini-types';
import { ITimApiPaymentResult } from '@paymash/capacitor-tim-api-plugin';
import { IMyPosTransactionResult } from '@paymash/capacitor-mypos-plugin';
import { SumupTransaction } from '@paymash/capacitor-sumup-plugin';
import { AdyenPaymentResponse } from '@pos-common/services/system/adyen/adyen-types';
import { TransactionResponse as MyposGlassTransactionResponse } from '@paymash/capacitor-mypos-glass-plugin';

/**
 * Created by y.belinsky on 3/21/18.
 */

export class PaymentResult implements IPaymentResult {
  private _date: string = null;
  private _cardName: string = null;
  private _merchantReceipt: string = null;
  private _cardholderReceipt: string = null;
  private _paymentProvider: string = null;
  private _terminalTransaction: { stan: string; deviceId?: string; timestamp?: string; paymentUuid?: string; nativeTippingAmount?: number } = null;

  constructor(paymentProvider?: string, terminalTransaction?: any) {
    if (paymentProvider) this.paymentProvider = paymentProvider;
    if (terminalTransaction) this.terminalTransaction = terminalTransaction;
  }

  public setPaymentResultData(paymentResult: any): void {
    switch (this.paymentProvider) {
      case PAYMENT_PROVIDERS.SUMUP:
        this.setSumUpPaymentResult(paymentResult);
        break;
      case PAYMENT_PROVIDERS.CCV:
        this.setCcvPaymentResult(paymentResult);
        break;
      case PAYMENT_PROVIDERS.SIX:
        this.setSixPaymentResult(paymentResult);
        break;
      case PAYMENT_PROVIDERS.OPI:
        this.setIgenicoPaymentResult(paymentResult);
        break;
      case PAYMENT_PROVIDERS.MYPOS:
        this.setMyPosPaymentResult(paymentResult);
        break;
      case PAYMENT_PROVIDERS.MYPOSMINI:
        this.setMyPosMiniPaymentResult(paymentResult);
        break;
      case PAYMENT_PROVIDERS.PAYMASH_PAY:
        this.setAdyenPaymentResult(paymentResult);
        break;
      case PAYMENT_PROVIDERS.MYPOSGLASS:
        this.setMyposGlassPaymentResult(paymentResult);
        break;
    }
  }

  private setSumUpPaymentResult(sumUpPaymentResult: SumupTransaction): void {
    this.date = new Date().toISOString();
    this.cardName = sumUpPaymentResult?.card?.type || null;
    this.cardholderReceipt = this.makeCardHolderReceiptForSumUp(sumUpPaymentResult);
  }

  private makeCardHolderReceiptForSumUp(sumUpPaymentResult: SumupTransaction): string {
    let cardholderReceipt: string = '  *** Customer receipt ***  \n';
    let cardLine = this.cardName ? this.cardName + '   ' : '';
    if (sumUpPaymentResult?.card?.last4Digits) {
      cardLine += 'X'.repeat(12) + sumUpPaymentResult.card.last4Digits;
    }
    cardholderReceipt += cardLine + '\n';
    cardholderReceipt += new Date(this.date).toLocaleString() + '\n';
    cardholderReceipt += 'Trn-ID:   ' + sumUpPaymentResult.transactionCode + '\n';
    cardholderReceipt += 'Total-EFT   ' + sumUpPaymentResult.currency + ' ' + sumUpPaymentResult.amount;
    return cardholderReceipt;
  }

  private setCcvPaymentResult(ccvPaymentResult: ICcvPaymentResult): void {
    this.date = new Date(ccvPaymentResult.timestamp).toISOString();
    this.cardName = ccvPaymentResult.cardName;
  }

  private setSixPaymentResult(timApiPaymentResult: ITimApiPaymentResult) {
    this.date = new Date(timApiPaymentResult.transactionInformation.timeStamp).toISOString();
    this.cardName = timApiPaymentResult.cardData.brandName || timApiPaymentResult.cardData.tenderName || null;

    enum TimAPiReceiptTypes {
      merchant = 'merchant',
      cardholder = 'cardholder',
    }

    timApiPaymentResult.printData.receipts.forEach((receipt) => {
      switch (receipt.recipient) {
        case TimAPiReceiptTypes.merchant:
          this.merchantReceipt = receipt.value;
          break;
        case TimAPiReceiptTypes.cardholder:
          this.cardholderReceipt = receipt.value;
          break;
      }
    });
  }

  private setIgenicoPaymentResult(ingenicoPaymentResult) {
    this.date = ingenicoPaymentResult.date;
    if (ingenicoPaymentResult.merchantReceipt.length !== 0)
      this.merchantReceipt = this.makeReceiptFromArr(ingenicoPaymentResult.merchantReceipt);
    if (ingenicoPaymentResult.cardholderReceipt.length !== 0)
      this.cardholderReceipt = this.makeReceiptFromArr(ingenicoPaymentResult.cardholderReceipt);
    this.paymentProvider = PAYMENT_PROVIDERS.OPI;
    this.terminalTransaction = ingenicoPaymentResult.terminalTransaction;
  }

  private setMyPosPaymentResult(myPosTransactionResult: IMyPosTransactionResult) {
    const { dateTime, cardBrand, stan, tid } = myPosTransactionResult;
    this.date = moment(dateTime, 'YYMMDDHHmmss').toISOString();
    this.cardName = cardBrand;
    this.paymentProvider = cardBrand;
    this.terminalTransaction = {
      stan: stan.toString(),
      deviceId: tid,
      timestamp: null,
    };
  }

  private setMyposGlassPaymentResult(glassPaymentResponse: MyposGlassTransactionResponse) {
    const {date, applicationName, stan, tid} = glassPaymentResponse;
    this.date = moment(date).toISOString();
    const cardName = applicationName;
    this.cardName = cardName;
    this.paymentProvider = cardName;
    this.terminalTransaction = {
      stan: stan?.toString(),
      deviceId: tid,
      timestamp: null,
    }
  }

  private setMyPosMiniPaymentResult(myPosMiniTransactionResult: TransactionResult) {
    const { localDate, cardTypeName, stan, terminalId } = myPosMiniTransactionResult;
    const transactionDate = moment(localDate, ['YYYY-MM-DD HH:mm:ss', 'ddd MMM DD HH:mm:ss Z YYYY']).toISOString();
    this.date = transactionDate;
    this.cardName = cardTypeName;
    this.paymentProvider = cardTypeName;
    this.terminalTransaction = {
      stan: stan,
      deviceId: terminalId,
      timestamp: transactionDate,
    };
  }

  private setAdyenPaymentResult(adyenPaymentResult: AdyenPaymentResponse) {
    const { timeStamp, cardName, serviceID, terminalID, merchantReceipt, cardholderReceipt, paymentUuid, tipAmount } = adyenPaymentResult;
    this.date = timeStamp;
    this.cardName = cardName;
    this.merchantReceipt = merchantReceipt;
    this.cardholderReceipt = cardholderReceipt;
    this.terminalTransaction = {
      stan: serviceID,
      deviceId: terminalID,
      timestamp: timeStamp,
      paymentUuid: paymentUuid,
      nativeTippingAmount: tipAmount
    };
  }

  private makeReceiptFromArr(arr): string {
    let receipt: string = '';
    arr.forEach((line) => {
      if (line !== undefined) receipt += line + '\n';
    });
    return receipt;
  }

  public get date(): string {
    return this._date;
  }

  public get cardName(): string {
    return this._cardName;
  }

  public get merchantReceipt(): string {
    return this._merchantReceipt;
  }

  public get cardholderReceipt(): string {
    return this._cardholderReceipt;
  }

  public get paymentProvider(): string {
    return this._paymentProvider;
  }

  public get terminalTransaction(): { stan: string; deviceId?: string; timestamp?: string; paymentUuid?: string; nativeTippingAmount?: number } {
    return this._terminalTransaction;
  }

  public set date(newDate: string) {
    if (!this._date) this._date = newDate;
  }

  public set cardName(newCardName: string) {
    if (!this._cardName) this._cardName = newCardName;
  }

  public set merchantReceipt(newMerchantReceipt: string) {
    if (!this._merchantReceipt) this._merchantReceipt = newMerchantReceipt;
  }

  public set cardholderReceipt(newCardholderReceipt: string) {
    if (!this._cardholderReceipt) this._cardholderReceipt = newCardholderReceipt;
  }

  public set paymentProvider(newPaymentProvider: string) {
    this._paymentProvider = newPaymentProvider;
  }

  public set terminalTransaction(newTerminalTransaction: { stan: string; deviceId?: string; timestamp?: string, paymentUuid?: string, nativeTippingAmount?: number }) {
    this._terminalTransaction = newTerminalTransaction;
  }
}
