import { Injectable } from '@angular/core';
import { BoardSoftPosAdyenTerminal } from './terminal.actions';
import { AdyenSoftPosInfo } from '@pos-common/services/system/adyen/classes';
import { AdyenSoftPosService } from '@pos-common/services/system/adyen/services/adyen-soft-pos.service';
import { Store } from '@ngrx/store';
import { AdyenSoftPosTargetAction } from '@pos-common/services/system/adyen/enums';

@Injectable()
export class SettingsStateFacade {

  constructor(
    private adyenSoftPosService: AdyenSoftPosService,
    private store: Store
    ) { }

  public boardSoftPosAdyen(params: Record<string, string>): void {
    const { boarded, boardingRequestToken, installationId, target } = params;
    this.store.dispatch(BoardSoftPosAdyenTerminal({
      info: new AdyenSoftPosInfo(
        boarded,
        installationId,
        boardingRequestToken
      ),
      target: target as AdyenSoftPosTargetAction
    }));
  }

  public actionAdyenSoftPosTerminal(params: Record<string, string>, url: URL): void {
    const softPOSAction: AdyenSoftPosTargetAction = this.adyenSoftPosService.gettingTargetActionSoftPos(url) as AdyenSoftPosTargetAction;

    switch (softPOSAction) {

      case AdyenSoftPosTargetAction.Payment: {
        const hasError: boolean = !!params?.['error'];
        const response: string = params?.['response'] || params?.['error'] || '';
        this.adyenSoftPosService.setResultPaymentFromSoftPos(response, hasError);
      } break;

      default: {
        this.boardSoftPosAdyen({
          ...params,
          target: softPOSAction
        });
      }

    }
  }

}
