import { PAYMASH_PROFILE } from '@profile';

interface IServerConfig {
  readonly localStorageApi: string;
  readonly APP_ENV: string;
  readonly LOCAL_IMAGES_URL: string;
  readonly SERVER_URL: string;
  readonly API_URL: string;
  readonly API_V2_URL: string;
  readonly API_LEGACY_URL: string;
  readonly PUBLIC_DOCUMENTS_URL: string;
  readonly PUBLIC_URL: string;
  readonly ADYEN_MANAGEMENT_URL: string;
  readonly ADYEN_TERMINAL_API_URL: string;
  readonly ADYEN_LEGACY_API_VERSION: string;
  readonly ADYEN_LATEST_API_VERSION: string;
  readonly ADYEN_PAYMENT_APPLICATION_SETTINGS: Record<string, string>;
}
export const SERVER_CONFIG: Partial<IServerConfig> = {};

Object.defineProperties(SERVER_CONFIG, {
  localStorageApi: {
    get: function () {
      const api = localStorage.getItem('debugAleternateApiInput');
      return !!api && api !== '' ? api : '';
    },
  },
  APP_ENV: { value: PAYMASH_PROFILE.APP_ENV, writable: true, enumerable: true },
  LOCAL_IMAGES_URL: { value: 'assets/images/', enumerable: true },
  SERVER_URL: {
    enumerable: true,
    get: function () {
      return this.localStorageApi || PAYMASH_PROFILE.SERVER_URL;
    },
  },
  API_URL: {
    enumerable: true,
    get: function () {
      return this.SERVER_URL + '/api/v1/';
    },
  },
  API_V2_URL: {
    enumerable: true,
    get: function () {
      return this.SERVER_URL + '/api/v2/';
    },
  },
  API_LEGACY_URL: {
    enumerable: true,
    get: function () {
      return this.SERVER_URL + '/api/legacy/';
    },
  },
  PUBLIC_DOCUMENTS_URL: {
    enumerable: true,
    get: function () {
      return this.SERVER_URL + '/public/documents/';
    },
  },
  PUBLIC_URL: {
    enumerable: true,
    get: function () {
      return this.SERVER_URL;
    },
  },
  ADYEN_MANAGEMENT_URL: { value: PAYMASH_PROFILE.adyen.managementUrl + '/', enumerable: true },
  ADYEN_TERMINAL_API_URL: { value: PAYMASH_PROFILE.adyen.terminalUrl + '/', enumerable: true },
  ADYEN_LEGACY_API_VERSION: { value: PAYMASH_PROFILE.adyen.legacyVersionApi + '/', enumerable: true },
  ADYEN_LATEST_API_VERSION: { value: PAYMASH_PROFILE.adyen.latestVersionApi + '/', enumerable: true },
  ADYEN_PAYMENT_APPLICATION_SETTINGS: { value: PAYMASH_PROFILE.adyen.paymentApp, enumerable: true },
});
