import { createAction, props } from '@ngrx/store';
import { AdyenSoftPosInfo, AdyenTerminalInfo } from '@pos-common/services/system/adyen/classes';
import { actionGroupName } from '../settings.action';
import { AdyenSoftPosTargetAction } from '@pos-common/services/system/adyen/enums';

const actionTerminalsSubGroupName: string = 'Terminals';

export const BoardSoftPosAdyenTerminal = createAction(
  `${actionGroupName}:${actionTerminalsSubGroupName} Board Soft Pos`,
  props<{ info: AdyenSoftPosInfo, target: AdyenSoftPosTargetAction }>()
);

export const AddSoftPosAdyenTerminal = createAction(
  `${actionGroupName}:${actionTerminalsSubGroupName} Add Soft Pos`,
  props<{ info: AdyenSoftPosInfo, target: AdyenSoftPosTargetAction }>()
)

export const AddSoftPosTerminalAdyenSuccess = createAction(
  `${actionGroupName}:${actionTerminalsSubGroupName} Add Soft Pos Success`,
  props<{ terminal: AdyenTerminalInfo }>()
)

export const BoardingSoftPosAdyenTerminal = createAction(
  `${actionGroupName}:${actionTerminalsSubGroupName} Boarding Soft Pos`,
  props<{ boardingRequestToken: string }>()
)

export const ErrorActionTerminal = createAction(
  `${actionGroupName}:${actionTerminalsSubGroupName} Error Action Settings`,
  props<{ error: Record<string, unknown> }>()
)
