import { Buffer } from 'buffer';

export class NexoDerivedKey {
    'cipherKey'?: Buffer | null;
    'hmacKey'?: Buffer | null;
    'iv'?: Buffer | null;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "cipherKey",
            "baseName": "cipherKey",
            "type": "Buffer"
        },
        {
            "name": "hmacKey",
            "baseName": "hmacKey",
            "type": "Buffer"
        },
        {
            "name": "iv",
            "baseName": "iv",
            "type": "Buffer"
        }    ];

    static getAttributeTypeMap() {
        return NexoDerivedKey.attributeTypeMap;
    }
}

