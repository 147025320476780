import { ErrorCondition } from '../enums';

export class AdyenPaymentError extends Error {
  public errorCondition: ErrorCondition;
  public refusalReason: string;
  public keyError: string;
  public isLegalError: boolean

  constructor(message: string, errorCondition: ErrorCondition = null, refusalReason: string = null, keyError: string = null, isLegalError: boolean = false) {
    super(message); // Call the Error constructor
    this.name = this.constructor.name; // Set error name (optional)
    this.errorCondition = errorCondition; // Add Adyen error condition
    this.refusalReason = refusalReason; // Add Adyen refusal reason
    this.keyError = keyError; // Add message code
    this.isLegalError = isLegalError; // if error is legal user action

    // Ensure the prototype is correctly assigned for instances of this class
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
