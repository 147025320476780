export class SecurityTrailer {
    'AdyenCryptoVersion'?: number;
    'Hmac'?: any | null;
    'KeyIdentifier'?: string;
    'KeyVersion'?: number;
    'Nonce'?: any | null;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "AdyenCryptoVersion",
            "baseName": "AdyenCryptoVersion",
            "type": "number"
        },
        {
            "name": "Hmac",
            "baseName": "Hmac",
            "type": "any"
        },
        {
            "name": "KeyIdentifier",
            "baseName": "KeyIdentifier",
            "type": "string"
        },
        {
            "name": "KeyVersion",
            "baseName": "KeyVersion",
            "type": "number"
        },
        {
            "name": "Nonce",
            "baseName": "Nonce",
            "type": "any"
        }    ];

    static getAttributeTypeMap() {
        return SecurityTrailer.attributeTypeMap;
    }
}

