import { MessageCategoryType } from '../enums/message-category-type.enum';
import { MessageClassType } from '../enums/message-class-type.enum';
import { MessageType } from '../enums/message-type.enum';

export class MessageHeader {
    'DeviceID'?: string;
    'MessageCategory': MessageCategoryType;
    'MessageClass': MessageClassType;
    'MessageType': MessageType;
    'POIID': string;
    'ProtocolVersion'?: string;
    'SaleID'?: string;
    'ServiceID'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "DeviceID",
            "baseName": "DeviceID",
            "type": "string"
        },
        {
            "name": "MessageCategory",
            "baseName": "MessageCategory",
            "type": "MessageCategoryType"
        },
        {
            "name": "MessageClass",
            "baseName": "MessageClass",
            "type": "MessageClassType"
        },
        {
            "name": "MessageType",
            "baseName": "MessageType",
            "type": "MessageType"
        },
        {
            "name": "POIID",
            "baseName": "POIID",
            "type": "string"
        },
        {
            "name": "ProtocolVersion",
            "baseName": "ProtocolVersion",
            "type": "string"
        },
        {
            "name": "SaleID",
            "baseName": "SaleID",
            "type": "string"
        },
        {
            "name": "ServiceID",
            "baseName": "ServiceID",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return MessageHeader.attributeTypeMap;
    }
}

