export const PAYMASH_PROFILE_COMMON = {
  APP_ENV: 'dev',
  isProduction: false,
  SERVER_URL: 'https://app.staging.mypaymash.com',
  isLoggerActive: true,
  sentryUrl: 'https://470324f4aa304d21ad5b88041a5ade3e@sentry.io/1200980',
  sentryEnvironment: 'development',
  requestTimeout: {
    status: 1000 * 30,
    normal: 1000 * 60,
    max: 1000 * 150,
    firstUpdate: 1000 * 60 * 8,
    retryAfter: 10,
    diagnosis: 1000 * 10,
    sendRequest: 1000 * 120,
  },
  retrySyncItemInterval: 5000,
  retrySyncItemIntervalMaxValue: 1000 * 20,
  retrySyncCount: 15,
  retrySyncMaxCount: 100,
  /**
   * @note Policy for Action: "s3:PutObject", Resource: "arn:aws:s3:::paymash-test/pos/*"
   */
  ASW: "MTIzLDM0LDk3LDM0LDU4LDEyMywzNCw5Nyw5OSw5OSwxMDEsMTE1LDExNSw3NSwxMDEsMTIxLDczLDEwMCwzNCw1OCwzNCw2NSw3NSw3Myw2NSw4NCw4OSw4Nyw1Myw2OSw1NSw3MSw1NSw5MCw4Myw4OSw3MCw3Miw3OCw4NSw4OSwzNCw0NCwzNCwxMTUsMTAxLDk5LDExNCwxMDEsMTE2LDY1LDk5LDk5LDEwMSwxMTUsMTE1LDc1LDEwMSwxMjEsMzQsNTgsMzQsMTAxLDg1LDQ3LDgyLDg1LDU0LDExNSw4NiwxMTQsNDcsMTA4LDUwLDc1LDEwNCwxMDEsNzcsNjUsMTEwLDExMCwxMDEsNzcsODgsNDcsMTE0LDY4LDg5LDc2LDEyMiw2OSw4OSw4Niw2OCw1MSw2Nyw0Myw1Myw0MywxMTEsMTE0LDQ5LDM0LDQ0LDM0LDExNCwxMDEsMTAzLDEwNSwxMTEsMTEwLDM0LDU4LDM0LDEwMSwxMTcsNDUsOTksMTAxLDExMCwxMTYsMTE0LDk3LDEwOCw0NSw0OSwzNCwxMjUsNDQsMzQsOTgsMzQsNTgsMTIzLDM0LDY2LDExNyw5OSwxMDcsMTAxLDExNiwzNCw1OCwzNCwxMTIsOTcsMTIxLDEwOSw5NywxMTUsMTA0LDQ1LDExNiwxMDEsMTE1LDExNiwzNCwxMjUsMTI1",
  LOGGER: {
    url: 'https://logs.fabware.com:45635',
    username: 'Mokohzik4IoFuYgh_X2ZDEV',
    password: '76L9oyKRTRHFLj5SUS17l-Z',
  },
  sendingLogsDelay: 0.5,
  printingTriesCount: 25,
  socketScanner: {
    developerId: '60CDCCA8-B0D4-41A0-AF84-18092176194F',
    iosAppKey: 'MC4CFQDHlPCy/4oPJ84WU32AZd6qWyi5WwIVAPPPUja1nVdgLTqY6yf1thBgyOBL',
    androidAppKey: 'MC4CFQC6E+QAKGnTvp43b+w9nimkmGoWZgIVAPbzjuNHQ8ArYeeMLhHf865G9B3s',
  },
  lokiJS: {
    prefix: 'pos',
    maxFileSize: 1570000,
  },
  fullCalendarLicenseKey: '0972986092-fcs-1657871549',
  adyen: {
    managementUrl: 'https://management-test.adyen.com',
    terminalUrl: 'https://terminal-api-test.adyen.com',
    legacyVersionApi: 'v1',
    latestVersionApi: 'v3',
    paymentApp: {
      packageName: 'com.adyen.ipp.mobile.companion.test',
      targetUrl: 'adyenpayments-test',
      backUrl: 'com.fabware.android5POS'
    }
  },
  intercom: {
    appId: 'f7ygz4ih',
    appType: 'Paymash POS Hybrid',
  },
  sumup: {
    apiKey: '5371115a-f0f1-493d-82ee-fc9db50138a6',
  },
  gaTrackingCode: 'UA-1808094-9',
};
