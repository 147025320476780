import { RootApplicationStateFacade } from './root';
import { EmployeesFacadeStore } from './employees';
import { InvoicesFacadeStore } from './invoices';
import { SettingsStateFacade } from './settings';

export const facades = [
  RootApplicationStateFacade,
  EmployeesFacadeStore,
  InvoicesFacadeStore,
  SettingsStateFacade
]
