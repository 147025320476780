import { AdyenTerminalInfo, AdyenTerminalSettingsInfo } from '../../classes';
import { AdyenApiHelper } from './adyen-api-helper.service';
import { Injectable } from '@angular/core';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import { ApiSoftPosBoardedSuccess, ApiTerminals, ApiTerminalSettings } from '../../adyen-types';
import { LoadingService } from '@pos-common/services/system/loading.service';
import { LogService } from '@pos-common/services/system/logger';
import { AdyenUrlSourceSettings } from '@pos-common/services/system/adyen/enums';
import { StorageKeys } from '@pos-common/constants';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { IActiveTerminal } from '@pos-common/interfaces';

@Injectable()
export class AdyenTerminalApi {
  private readonly baseUrl = SERVER_CONFIG.ADYEN_MANAGEMENT_URL + SERVER_CONFIG.ADYEN_LATEST_API_VERSION;
  private readonly baseUrlLegacy = SERVER_CONFIG.ADYEN_MANAGEMENT_URL + SERVER_CONFIG.ADYEN_LEGACY_API_VERSION;
  private readonly logger = this.logService.createLogger('AdyenTerminalApi');

  constructor(
    private readonly apiHelper: AdyenApiHelper,
    private readonly loadingService: LoadingService,
    private localStorage: LocalStorage,
    private logService: LogService
  ) {}

  getTerminals(options: { merchantId: string }): Promise<AdyenTerminalInfo[]> {
    const pageSizeDefault: string = '100';
    const url = `${this.baseUrl}terminals?merchantIds=${options.merchantId}&pageSize=${pageSizeDefault}`;
    this.loadingService.showLoadingItem();
    return this.apiHelper
      .get(url, { errorHandler: this.processError.bind(this) })
      .then((terminals: ApiTerminals): AdyenTerminalInfo[] => {
        terminals.data = terminals?.data || [];
        return terminals.data.map(({ model, serialNumber }): AdyenTerminalInfo => new AdyenTerminalInfo(model, serialNumber));
      })
      .finally(() => this.loadingService.hideLoadingItem());
  }

  async getTokenSoftPos(options: { merchantId: string; boardingRequestToken: string }): Promise<ApiSoftPosBoardedSuccess> {
    const url: string = `${this.baseUrlLegacy}merchants/${options.merchantId}/generatePaymentsAppBoardingToken`;
    this.loadingService.showLoadingItem();
    return this.apiHelper
      .post(url, { boardingRequestToken: options.boardingRequestToken }, { errorHandler: this.processError.bind(this) })
      .then((data: ApiSoftPosBoardedSuccess): ApiSoftPosBoardedSuccess => data)
      .catch((error) => error)
      .finally((): void => this.loadingService.hideLoadingItem());
  }

  async revokeSoftPosApplication(options: { merchantId: string, installationId: string }): Promise<unknown> {
    const url: string = `${this.baseUrlLegacy}merchants/${options.merchantId}/paymentsApps/${options.installationId}/revoke`;

    return this.apiHelper
      .post(url, {}, { errorHandler: this.processError.bind(this) })
      .then((): unknown => true)
      .catch((error) => {
        this.logger.error(error, 'revokeSoftPosApplication');
      })
      .finally((): void => this.loadingService.hideLoadingItem());
  }

  getTerminalSettings(id: string, urlSourceSettings: AdyenUrlSourceSettings = AdyenUrlSourceSettings.MERCHANT): Promise<AdyenTerminalSettingsInfo> {
    const url: string = `${this.baseUrl}/${urlSourceSettings}/${id}/terminalSettings`;

    const activeTerminal: IActiveTerminal = JSON.parse(this.localStorage.get(StorageKeys.activeTerminal));
    const defaultSettings: AdyenTerminalSettingsInfo = new AdyenTerminalSettingsInfo(activeTerminal?.id, false);

    this.loadingService.showLoadingItem();
    return this.apiHelper.get(url, { errorHandler: this.processError.bind(this) })
      .then((current: ApiTerminalSettings): AdyenTerminalSettingsInfo => {

          if (!current) {
            this.logger.error(new Error('Adyen device settings'), `${urlSourceSettings}: Could not get the settings for the id ${id}`);
          }

          return current ? new AdyenTerminalSettingsInfo(
            defaultSettings.id,
            current.gratuities.length > 0,
            current.nexo
          ) : defaultSettings;

      })
      .finally(() => this.loadingService.hideLoadingItem())

  }

  private processError(error: unknown): Error {
    const apiErrorDetails = this.decodeApiErrorDetails(error);
    if (!apiErrorDetails) {
      this.logger.error(error, 'Unknown api error');
      return new Error('Unknown api error');
    }
    return new Error(apiErrorDetails.title);
  }

  private decodeApiErrorDetails(error: any) {
    if (typeof error !== 'object') return undefined;
    if (!error.error || typeof error.error !== 'string') return undefined;

    const errorDetailsString = error.error;

    try {
      const errorDetails: { title: string } = JSON.parse(errorDetailsString);
      return errorDetails;
    } catch (e) {
      return undefined;
    }
  }
}
