import { Injectable } from '@angular/core';
import { Appointment } from '@pos-common/classes/appointment/appointment.class';
import { Product } from '@pos-common/classes/product.class';
import { Service } from '@pos-common/classes/service/service.class';
import { APPOINTMENT_DEFAULT_COLOR, APPOINTMENT_STEPS } from '@pos-common/constants';
import { UuidService } from '@pos-common/services/utils/uuid.utils';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { LogService } from '../logger/log.service';
import { Image } from '@pos-common/classes/image.class';
import { Employee } from '@pos-common/classes/employee.class';
import moment from 'moment';
import { Customer } from '@pos-common/classes/customer.class';
import { LOCALE } from '@pos-common/constants/locale.const';
import { Invoice } from '@pos-common/classes/invoice.class';
import { InvoicesService } from '../invoices.service';
import { IAppointmentServiceResponse } from '@pos-common/interfaces';
import { ProductVariant } from '@pos-common/classes/product-variant.class';
import { ProductVariantProvider } from '@pos-common/services/resources/product-variant-db-entity.provider';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';
import { CartService } from '../cart.service';
import { SecurityService } from '../security.service';
import { VatRateProvider } from '@pos-common/services/resources';
import { VatRate } from '@pos-common/classes/vat-rate.class';
import { EmployeesProvider } from '@pos-common/services/resources/employees-db-entity.provider';
import { oneOf, Query } from '@paymash/capacitor-database-plugin';
import { trustValue } from '@pos-common/services';

@Injectable()
export class AppointmentService {
  private currentSlide$ = new BehaviorSubject<APPOINTMENT_STEPS>(APPOINTMENT_STEPS.DETAILS);
  private activeAppointment$ = new BehaviorSubject<Appointment>(this.createAppointment());
  private defaultEmployee: Employee = null;
  private readonly logger = this.logService.createLogger('AppointmentService');

  constructor(
    private cartService: CartService,
    private vatRateProvider: VatRateProvider,
    private securityService: SecurityService,
    private invoicesService: InvoicesService,
    private uuidService: UuidService,
    private productVariantProvider: ProductVariantProvider,
    private employeesProvider: EmployeesProvider,
    private logService: LogService
  ) {}

  getCurrentSlide() {
    return this.currentSlide$.asObservable();
  }

  getCurrentSlideValue() {
    return this.currentSlide$.getValue();
  }

  setCurrentSlide(slide: APPOINTMENT_STEPS) {
    return this.currentSlide$.next(slide);
  }

  getActiveAppointment() {
    return this.activeAppointment$.asObservable();
  }

  getActiveAppointmentValue() {
    return this.activeAppointment$.getValue();
  }

  setActiveAppointment(appointment: Appointment) {
    return this.activeAppointment$.next(new Appointment(appointment));
  }

  createAppointment(newAppointment?: Appointment) {
    const appointment = new Appointment(newAppointment || {});
    appointment.uuid = appointment.uuid || this.uuidService.generate();
    return appointment;
  }

  createService(newService: Service) {
    const service = new Service(newService || {});
    service.uuid = service.uuid || this.uuidService.generate();
    service.isNew = true;
    return service;
  }

  async createInvoiceByAppointment(activeInvoice: Invoice, appointment: Appointment, services: IAppointmentServiceResponse[]) {
    return new Promise<Invoice>((resolve, reject) => {
      let invoice = this.invoicesService.createInvoice();
      invoice.uuid = activeInvoice.uuid;
      invoice.publicUuid = activeInvoice.uuid.replace(/-/g, '');
      invoice = this.cartService.setCustomerDataToInvoice(invoice, appointment.customer);
      const variantUuids: string[] = services.reduce((uuids, serviceResponse) => {
        return [...uuids, serviceResponse.variant.uniqueIdentifier];
      }, []);

      const company = this.securityService.getLoggedCompanyData();
      const queryParams: Query = { uuid: oneOf(...variantUuids) };

      forkJoin([
        this.productVariantProvider.getListByParams(queryParams),
        this.vatRateProvider.getByUuid(company.defaultVATRate.uuid),
      ]).subscribe(([variants, companyVatRate]) => {
        services.forEach((serviceResponse, index) => {
          const { variant: variantResponse, category: categoryResponse } = serviceResponse;
          const appointmentService = appointment.services[index];
          const variant = variants.find((variant) => variant.uuid === variantResponse.uniqueIdentifier);
          const productType = variant ? PRODUCT_TYPES.SERVICE : PRODUCT_TYPES.INDIVIDUAL;
          const vatRate = variant?.vatRate || companyVatRate;
          const invoiceEntryData = this.makeInvoiceEntryToAddData(variant, vatRate, appointmentService, categoryResponse?.uniqueIdentifier);
          const currentCartEntryIndex = this.invoicesService.getEntryByUuidAndCategory(
            productType,
            invoiceEntryData,
            invoice.invoiceEntries
          );
          const quantity = 1;
          if (!currentCartEntryIndex?.length) {
            invoice = this.invoicesService.addInvoiceEntryToInvoice(invoice, invoiceEntryData, productType, quantity);
            return;
          }
          const currentEntry = invoice.invoiceEntries[currentCartEntryIndex[0]];
          currentEntry.quantity += quantity;
          currentEntry.quantityForKitchenReceipt += quantity;
          currentEntry.localModificationDate = moment.utc().toISOString();
        });
        this.invoicesService.calculateInvoiceAmountAfterDiscount(invoice);
        resolve(invoice);
      }, reject);
    });
  }

  updateActiveInternalNote(internalNote: string) {
    const appointment = this.getActiveAppointmentValue();
    appointment.internalNote = internalNote;
    this.setActiveAppointment(appointment);
  }

  addServiceToAppointment(appointment: Appointment, newService: Service): Appointment {
    const service = this.createService(newService);
    appointment.services = [...appointment.services, service];
    this.logger.info('Service has been added with quantity 1 to appointment', {
      serviceUuid: service.uuid,
      appointmentUuid: appointment.uuid,
    });
    return new Appointment(appointment);
  }

  makeServiceToAddData(product: Product, customPrice: number, employee: Employee): any {
    let image = null;
    if (product.images?.[0]) {
      image = new Image(product.images[0].image);
    }
    const { serviceDuration, servicePostProcessingTime, servicePreparationTime } = product.serviceOptions;
    const totalDuration = this.getTotalDuration(serviceDuration, servicePreparationTime, servicePostProcessingTime);
    return {
      price: customPrice?.toFixed(2),
      serviceName: product.name,
      serviceUUID: product.uuid,
      image,
      duration: serviceDuration,
      postProcessingTime: servicePostProcessingTime,
      preparationTime: servicePreparationTime,
      totalDuration,
      employee,
    };
  }

  addServiceToActiveAppointment(newService: Service) {
    let activeAppointment = this.getActiveAppointmentValue();
    const index = this.getIndexServiceByUuid(newService.uuid);
    if (index === -1) {
      this.logger.info(`Service ${newService.uuid} has been added to appointment - ${activeAppointment.uuid}`);
      activeAppointment = this.addServiceToAppointment(activeAppointment, newService);
      const isFirstService = activeAppointment.services.length === 1;
      if (isFirstService) {
        this.updateColorByEmployeeColor(activeAppointment);
      }
    } else {
      this.logger.info('Service has been updated for appointment', {
        serviceUuid: activeAppointment.services[index].uuid,
        appointmentUuid: activeAppointment.uuid,
      });
      activeAppointment.services[index] = this.createService(newService);
    }
    this.updateEndedAt(activeAppointment);
    this.setActiveAppointment(activeAppointment);
  }

  deleteServiceFromActiveAppointment(newService: Service) {
    let activeAppointment = this.getActiveAppointmentValue();
    const index = this.getIndexServiceByUuid(newService.uuid);
    if (index > -1) {
      this.logger.info('Service has been deleted from appointment', {
        serviceUuid: activeAppointment.services[index].uuid,
        appointmentUuid: activeAppointment.uuid,
      });
      activeAppointment.services.splice(index, 1);
      const isFirstServiceDeleted = index === 0;
      if (isFirstServiceDeleted) {
        this.updateColorByEmployeeColor(activeAppointment);
      }
      this.updateEndedAt(activeAppointment);
      this.setActiveAppointment(activeAppointment);
    }
  }

  setCustomer(customer: Customer) {
    const appointment = this.getActiveAppointmentValue();
    appointment.customer = customer;
    this.setActiveAppointment(appointment);
  }

  setInternalNote(internalNote: string, data: any) {
    if (!data) {
      return;
    }
    const { note: newInternalNote } = data;
    if (internalNote === newInternalNote) {
      return;
    }
    this.updateActiveInternalNote(newInternalNote);
  }

  setColor(color: string, data: any) {
    if (!data) {
      return;
    }
    const { color: newColor } = data;
    if (color === newColor) {
      return;
    }
    const appointment = this.getActiveAppointmentValue();
    appointment.colorInCalendar = newColor;
    this.setActiveAppointment(appointment);
  }

  setCustomService(activeService: Service, data: { service?: Service }) {
    if (!data) {
      return;
    }
    if (!data.service) {
      return this.deleteServiceFromActiveAppointment(activeService);
    }
    this.addServiceToActiveAppointment(data.service);
  }

  setStartAt(startedAt: string, data: any) {
    if (!data) {
      return;
    }
    const { startedAt: newStartAt } = data;
    if (startedAt === newStartAt) {
      return;
    }
    const appointment = this.getActiveAppointmentValue();
    appointment.startedAt = moment(newStartAt).format(LOCALE.DateFormat.YYYY_MM_DD_HH_mm_DASH);
    this.updateEndedAt(appointment);
    this.setActiveAppointment(appointment);
  }

  getTotalDuration(duration: string, preparationTime: string, postProcessingTime: string) {
    const durations = [duration, preparationTime, postProcessingTime];
    return this.calculateTotalDuration(durations);
  }

  updateEndedAt(activeAppointment: Appointment) {
    let { startedAt } = activeAppointment;
    if (activeAppointment.services.length) {
      activeAppointment.services.forEach((service) => {
        const [hours, minutes] = service.totalDuration.split(':');
        startedAt = moment(startedAt).add(hours, 'hours').add(minutes, 'minutes').toISOString();
      });
    }
    activeAppointment.endedAt = moment(startedAt).format(LOCALE.DateFormat.YYYY_MM_DD_HH_mm_DASH);
  }

  hasAppointmentChanges(defaultAppoinment: Appointment, activeAppointment: Appointment) {
    const defaultValue = new Appointment(defaultAppoinment);
    defaultValue.services.forEach((service) => (service.image = null));
    const activeValue = new Appointment(activeAppointment);
    activeValue.services.forEach((service) => (service.image = null));
    return JSON.stringify(defaultValue) !== JSON.stringify(activeValue);
  }

  getDefaultEmployee() {
    const activeAppointment = this.getActiveAppointmentValue();
    return this.defaultEmployee && !activeAppointment.services.length ? this.defaultEmployee : null;
  }

  setDefaultEmployee(employeeUuid: string) {
    if (!employeeUuid) {
      this.defaultEmployee = null;
      return;
    }
    this.employeesProvider.getByUuid(employeeUuid).subscribe(
      (employee) => {
        this.defaultEmployee = employee;
      },
      (error) => {
        this.defaultEmployee = null;
        this.logger.error(error, 'setDefaultEmployee:employeesProvider:getByUuid:employeeUuid', undefined, { employeeUuid });
      }
    );
  }

  private getIndexServiceByUuid(uuid: string) {
    const appointment = this.getActiveAppointmentValue();
    return appointment.services.findIndex((service) => service.uuid === uuid);
  }

  private updateColorByEmployeeColor(appointment: Appointment) {
    let colorInCalendar = APPOINTMENT_DEFAULT_COLOR;
    if (appointment.services.length && appointment.services[0].employee) {
      colorInCalendar = appointment.services[0].employee.colorInCalendar;
    }
    appointment.colorInCalendar = colorInCalendar;
  }

  private makeInvoiceEntryToAddData(variant: ProductVariant, vatRate: VatRate, service: Service, productCategoryUuid: string): any {
    return {
      variant,
      price: service?.price,
      taxRate: trustValue(vatRate?.value) ? vatRate.value : 0,
      productCategory: productCategoryUuid || null,
      name: service?.serviceName,
      image: service?.image,
      wasPrice: variant?.wasPrice || 0,
    };
  }

  private calculateTotalDuration(durations: string[]) {
    const minutesInHour = 60;
    const totalMinutesDuration = durations.reduce((totalMinutes, duration) => {
      const [hours, minutes] = duration.split(':');
      totalMinutes += parseInt(hours) * minutesInHour + parseInt(minutes);
      return totalMinutes;
    }, 0);
    const hours = Math.floor(totalMinutesDuration / minutesInHour);
    const minutes = totalMinutesDuration % minutesInHour;
    return `${this.getTimeValue(hours)}:${this.getTimeValue(minutes)}`;
  }

  private getTimeValue(time: number) {
    return time.toString().padStart(2, '0');
  }
}
