import { createReducer } from '@ngrx/store';

export const terminalsKeyState = 'terminals';

export interface SettingsTerminalPOSState {
}

const initialState: SettingsTerminalPOSState = {
};

export const reducerSettingTerminalsPOS = createReducer(
  initialState
);
