import { AdyenPaymentError } from '@pos-common/services/system/adyen/classes';

export class ErrorTranslationHelper {
  private messages: Map<string, string>;

  setupMessages(info: [key: string, value: string][]) {
    this.messages = new Map<string, string>(info);
  }

  getTranslationKey(key: string | undefined): string | undefined {
    if (!key) {
      return;
    }

    const keys: string[] = Array.from(this.messages.keys());
    const foundKey: string = keys.find((value: string): boolean => key === value) || keys.find((value: string): boolean => key.includes(value));
    return this.messages.get(foundKey);
  }

  threatModelChecker(error: AdyenPaymentError): string {
    const { message, errorCondition } = error;
    if (message) {
      const match: RegExpMatchArray = message.match(/threatType=([A-Z_]+)/);
      if (match) {
        return match[1] ? errorCondition + ': ' + match[1].replace(/_/g, ' ').toLowerCase() : 'adyen_payment_process_common_error_msg';
      }
      return message;
    }
    return message;
  }

}
