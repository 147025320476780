import { AfterViewInit, Component, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, ViewEncapsulation } from '@angular/core';
import { NavParams, ModalController, ViewWillLeave } from '@ionic/angular';
import { LogService } from '../../../common/services/system/logger/log.service';
import { CameraScannerService } from '../../../common/services/system/camera-scanner.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IGiftCardValidators } from '../interfaces/giftCardValidators.interface';
import { GiftCardProvider } from '../gift-card.provider';
import moment from 'moment';
import { GiftCard } from '../gift-card';
import { AlertService } from '@pos-common/services/system/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize, filter } from 'rxjs/operators';
import { SERVER_RESPONSE_ERRORS } from '@pos-common/constants/server-response-errors.const';
import { HttpErrorResponse } from '@angular/common/http';
import { SubSink } from 'subsink';
import { AssignGiftCardTitleState } from '../constants/assign-gift-card-title-state.enum';
import { KeyboardService } from '@pos-common/services/system/keyboard/keyboard.service';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';
import { BarcodeScannerTypes } from '@pos-common/constants/barcode-scanner-types.enum';

@Component({
  selector: 'assign-gift-card',
  templateUrl: './assign-gift-card.component.html',
  styleUrls: ['./assign-gift-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignGiftCardComponent implements OnInit, AfterViewInit, OnDestroy, ViewWillLeave {
  inProcess = false;
  giftCardForm: FormGroup;
  isCameraOpen = false;
  selectedGiftCard: GiftCard = null;
  giftCardCode = '';
  title: string = null;

  private giftCardValidators: IGiftCardValidators;
  private subs = new SubSink();
  private translations = new Map<AssignGiftCardTitleState, string>();
  private isAssign = false;
  private readonly slashRegex = /^[^\/]+$/;
  private readonly logger = this.logService.createLogger('AssignGiftCardComponent');

  constructor(
    private viewCtrl: ModalController,
    private cameraScannerService: CameraScannerService,
    private params: NavParams,
    private giftCardProvider: GiftCardProvider,
    private alertService: AlertService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    private setTimeoutUtil: SetTimeoutUtil,
    private logService: LogService
  ) {
    this.isAssign = this.params.get('isAssign') || false;
    this.giftCardCode = this.params.get('giftCardCode');
    this.giftCardValidators = this.params.get('validators') || {};
    this.giftCardForm = new FormGroup({
      giftCardCode: new FormControl(this.giftCardCode, [Validators.pattern(this.slashRegex)]),
    });
    if (!window.cordova) {
      this.closeCameraScanner();
    }
    this.setTranslation();
  }

  ngOnInit(): void {
    this.cameraScannerService.setBarcodeScannerType(BarcodeScannerTypes.Paymash);
    this.setTranslationToTitle();
    this.subs.sink = this.cameraScannerService
      .getBarcodeScannerOpenedStateEvent()
      .pipe(filter(() => this.isCameraOpen))
      .subscribe((isOpened) => {
        this.isCameraOpen = isOpened;
        this.setTranslationToTitle();
        this.cdr.detectChanges();
      });
  }

  private setTranslation() {
    this.translations.set(AssignGiftCardTitleState.assign, 'product_details_modal_assign_gift_card_number_title');
    this.translations.set(AssignGiftCardTitleState.code, 'product_details_modal_enter_gift_card_code_title');
    this.translations.set(AssignGiftCardTitleState.scan, 'product_details_modal_scan_gift_card_code_title');
  }

  private setTranslationToTitle() {
    if (this.isAssign) {
      this.title = this.translations.get(AssignGiftCardTitleState.assign);
      return;
    }
    if (this.isCameraOpen) {
      this.title = this.translations.get(AssignGiftCardTitleState.scan);
      return;
    }
    this.title = this.translations.get(AssignGiftCardTitleState.code);
  }

  ngAfterViewInit(): void {
    this.subs.sink = this.cameraScannerService.newBarcodeEvent.pipe(filter(() => this.isCameraOpen)).subscribe((newBarcode) => {
      this.giftCardForm.patchValue({
        giftCardCode: newBarcode,
      });
      this.closeCameraScanner();
    });
  }

  ionViewWillLeave(): void {
    this.closeCameraScanner();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  toggleCameraOrKeyboard() {
    if (this.keyboardService.isOpen) {
      this.keyboardService.getCloseEventOnce().subscribe(() => this.openCamera());
      return;
    }
    this.openCamera();
  }

  private openCamera() {
    this.isCameraOpen = true;
    document.body.classList.add('open-barcodescanner');
    this.cdr.detectChanges();
    this.setTimeoutUtil.waitTimeAndDo(300).then(() => {
      this.cameraScannerService.openBarcodeScanner(document.querySelector('assign-gift-card .camera-area-content'));
    });
  }

  closeCameraScanner() {
    document.body.classList.remove('open-barcodescanner');
    this.cameraScannerService.closeBarcodeScanner();
  }

  close() {
    if (this.giftCardCode) {
      this.selectedGiftCard = new GiftCard({ physicalCode: this.giftCardCode });
    }
    this.dismiss();
  }

  dismiss() {
    this.inProcess = false;
    this.viewCtrl.dismiss(this.selectedGiftCard).catch((error) => this.logger.error(error, 'dismiss'));
  }

  applyGiftCard() {
    if (this.inProcess) {
      return;
    }
    if (this.giftCardProvider.isOffline() || !this.giftCardForm.valid) {
      this.inProcess = false;
      return;
    }
    this.inProcess = true;
    let { giftCardCode } = this.giftCardForm.value;
    if (giftCardCode) {
      giftCardCode = giftCardCode.trim();
      this.getGiftCard(giftCardCode)
        .pipe(finalize(() => this.finalize()))
        .subscribe(
          (giftCard) => this.setGiftCardData(giftCard),
          (err) => this.errorApplyHandle(err, giftCardCode)
        );
      return;
    }
    this.dismiss();
  }

  private getGiftCard(giftCardCode: string) {
    const regxGiftCardCode = new RegExp('^([\\d\\w]{4}\\s){3}[\\d\\w]{4}$', 'i');
    if (regxGiftCardCode.test(giftCardCode)) {
      return this.giftCardProvider.getGiftCardByCode(giftCardCode);
    }
    return this.giftCardProvider.getGiftCardByPhysicalCode(giftCardCode);
  }

  private finalize() {
    this.inProcess = false;
    this.cdr.markForCheck();
  }

  private setGiftCardData(giftCard: GiftCard) {
    const { alreadyExist, balance, expired } = this.giftCardValidators;
    if (alreadyExist) {
      return this.showInfoMessage('giftcard_already_exist_title', 'giftcard_already_exist_msg');
    }

    const selectedGiftCard = new GiftCard(giftCard);
    if (expired) {
      const timeNow = moment.utc();
      const expirationDate = moment(selectedGiftCard.expirationDate);
      const diffTime = timeNow.diff(expirationDate, 'minutes');
      if (diffTime > 0) {
        return this.showInfoMessage('giftcard_expired_title', 'giftcard_expired_msg');
      }
    }
    if (balance && selectedGiftCard.balance <= 0) {
      return this.showInfoMessage('giftcard_no_balance_title', 'giftcard_no_balance_msg');
    }
    this.selectedGiftCard = selectedGiftCard;
    this.dismiss();
  }

  private errorApplyHandle(err: HttpErrorResponse, giftCardCode: string) {
    const { alreadyExist, existingGiftCarts } = this.giftCardValidators;
    if (err.status === SERVER_RESPONSE_ERRORS.GIFT_CARD_STATUS_NOT_FOUND) {
      if (alreadyExist) {
        if (existingGiftCarts && existingGiftCarts.includes(giftCardCode)) {
          return this.showInfoMessage('giftcard_already_exist_title', 'giftcard_already_exist_msg');
        }
        this.selectedGiftCard = new GiftCard({ physicalCode: giftCardCode });
        return this.dismiss();
      }
      this.showInfoMessage('giftcard_not_found_title', 'giftcard_not_found_msg');
    }
    this.logger.error(err, 'applyGiftCard:getGiftCardByCode', undefined, { giftCardCode });
  }

  private async showInfoMessage(title: string, message: string) {
    const alert = await this.alertService.create({
      header: this.translateService.instant(title),
      message: this.translateService.instant(message),
      buttons: ['OK'],
    });
    await alert.present().catch((err) => this.logger.error(err, 'showInfoMessage:present'));
  }
}
