import { Directive, Input, NgModule, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { InvoceInlist } from '@pos-common/classes/invoice-in-list.class';
import { INVOICE_TYPES } from '@pos-common/constants/invoice-types';
import { EmployeesFacadeStore } from '@pos-stores/employees';
import { Employee } from '@pos-common/classes/employee.class';

@Directive({
  selector: '[posShowRemoveButtonForInvoice]',
})
export class ShowRemoveButtonForInvoiceDirective implements OnChanges {
  @Input('posShowRemoveButtonForInvoice') invoiceInList: InvoceInlist;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private employeeFacadeStore: EmployeesFacadeStore
    ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes.invoiceInList;
    if (change) {
      this.updateTemplate();
    }
  }

  private updateTemplate() {
    this.viewContainer.clear();
    if (this.isAllowDisplayRemoveButton()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private isAllowDisplayRemoveButton() {
    const currentEmployee: Employee = this.employeeFacadeStore.activeEmployeeSnapshot;
    const hasCancellationPermission = currentEmployee?.hasCancellationPermission || false;
    const isOriginInvoice = !this.invoiceInList.isCancelled && !this.invoiceInList.cancellationInvoiceReference;
    const isPartiallyCancelled = this.invoiceInList.isPartiallyCancelled && !!this.invoiceInList.cancellationInvoiceReference;
    return (
      (isOriginInvoice && hasCancellationPermission) ||
      (this.invoiceInList?.isDraft && isOriginInvoice) ||
      (!this.invoiceInList?.isDraft &&
        this.invoiceInList.invoiceType === INVOICE_TYPES.INVOICE &&
        hasCancellationPermission &&
        (isOriginInvoice || isPartiallyCancelled))
    );
  }
}
@NgModule({
  declarations: [ShowRemoveButtonForInvoiceDirective],
  exports: [ShowRemoveButtonForInvoiceDirective],
})
export class ShowRemoveButtonForInvoiceDirectiveModule {}
