import { PAYMASH_PROFILE_COMMON } from './profile.common';
const profile: Partial<typeof PAYMASH_PROFILE_COMMON> = {
  adyen: {
    managementUrl: 'https://management-test.adyen.com',
    terminalUrl: 'https://terminal-api-test.adyen.com',
    legacyVersionApi: 'v1',
    latestVersionApi: 'v3',
    paymentApp: {
      packageName: 'com.adyen.ipp.mobile.companion.test',
      targetUrl: 'adyenpayments-test',
      backUrl: 'com.fabware.android5POS'
    }
  },
};
export const PAYMASH_PROFILE: Partial<typeof PAYMASH_PROFILE_COMMON> = Object.assign(PAYMASH_PROFILE_COMMON, profile);
