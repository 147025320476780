import { ActionReducerMap } from '@ngrx/store';
import { reducerRootApplication, RootApplicationState } from './root';
import { EmployeeState, reducerEmployeesPage } from './employees';
import { InvoicesState, reducerInvoices } from './invoices/invoices.reducer';
import { reducerSettingTerminalsPOS, SettingsTerminalPOSState } from './settings/terminal/terminal.reducer';

export interface ApplicationRootState {
  root: RootApplicationState;
  employees: EmployeeState;
  invoices: InvoicesState;
  settings: SettingsTerminalPOSState
}

export const reducers: ActionReducerMap<ApplicationRootState> = {
  root: reducerRootApplication,
  employees: reducerEmployeesPage,
  invoices: reducerInvoices,
  settings: reducerSettingTerminalsPOS
}
