export enum DefaultPaymentMethods {
  CASH = 'CASH',
  MAESTRO = 'MAESTRO',
  POSTFINANCE = 'POSTFINANCE',
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  GENERIC_CC = 'GENERIC_CC',
  BANK_TRANSFER = 'BANK_TRANSFER',
  PAYPAL = 'PAYPAL',
  ON_INVOICE = 'ON_INVOICE',
  MYPOS = 'MYPOS',
  MYPOS_GLASS = 'MYPOSGLASS',
  SOFTPOS = 'SOFTPOS',
  GIFTCARD = 'GIFTCARD_NEW',
  TWINT = 'TWINT',
  SUMUP = 'SUMUP',
}
