export const timingSafeEqual = (a: NodeJS.ArrayBufferView, b: NodeJS.ArrayBufferView): boolean => {
  if (a.byteLength !== b.byteLength) {
    return false;
  }

  const bufA = Buffer.from(a.buffer, a.byteOffset, a.byteLength);
  const bufB = Buffer.from(b.buffer, b.byteOffset, b.byteLength);

  let result = 0;
  for (let i = 0; i < bufA.length; i++) {
    result |= bufA[i] ^ bufB[i];
  }

  return result === 0;
}
