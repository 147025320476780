import { pbkdf2Sync } from "crypto";
import { AdyenNexoEnum } from '../../enums';
import { NexoDerivedKey } from '../../classes';

export class NexoDerivedKeyGeneratorUtil {
    public static deriveKeyMaterial(passphrase: string): NexoDerivedKey {
        const pass = Buffer.from(passphrase, "binary");
        const salt = Buffer.from("AdyenNexoV1Salt", "binary");
        const iterations = 4000;
        const keylen = AdyenNexoEnum.CIPHER_KEY_LENGTH + AdyenNexoEnum.HMAC_KEY_LENGTH + AdyenNexoEnum.IV_LENGTH;
        const key = pbkdf2Sync(pass, salt, iterations, keylen * 8, "sha1");
        return NexoDerivedKeyGeneratorUtil.readKeyData(key);
    }

    private static readKeyData(key: Buffer): NexoDerivedKey {
        return {
            cipherKey: key.slice(AdyenNexoEnum.HMAC_KEY_LENGTH, AdyenNexoEnum.HMAC_KEY_LENGTH + AdyenNexoEnum.CIPHER_KEY_LENGTH),
            hmacKey: key.slice(0, AdyenNexoEnum.HMAC_KEY_LENGTH),
            iv: key.slice(
              AdyenNexoEnum.HMAC_KEY_LENGTH + AdyenNexoEnum.CIPHER_KEY_LENGTH,
              AdyenNexoEnum.CIPHER_KEY_LENGTH + AdyenNexoEnum.HMAC_KEY_LENGTH + AdyenNexoEnum.IV_LENGTH,
            ),
        };
    }
}
