export class AdyenTerminalSettingsInfo {
  constructor(
    public readonly id: string,
    public readonly hasTipping: boolean,
    public readonly nexo?: {
      encryptionKey: {
        identifier: string,
        passphrase: string,
        version: number
      }
    }
  ) {}
}
