import { TerminalNames } from '@pos-common/constants/terminal-names.enum';
import { PAYMENT_METHOD_NAMES } from '@pos-common/constants';
import { AdyenTerminalInfo } from '@pos-common/services/system/adyen/classes';
import { IActiveTerminal } from '@pos-common/interfaces';

export const softPOsTerminalHelper = (terminal: AdyenTerminalInfo): IActiveTerminal => {
  return {
    connected: true,
    name: TerminalNames.SOFTPOS,
    title: PAYMENT_METHOD_NAMES.PAYMASH_PAY,
    tipAllowed: false,
    ...terminal,
  }
}
